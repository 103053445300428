const insurances = [
  { name: 'Care Plus' }, //Alivi
  { name: 'Solis' }, //Alivi
  { name: 'Simply' }, // Trip2  ...con Medicare ID con letras y números
  { name: 'Aetna' }, //Modivcare
  { name: 'Sunshine' }, //Modivcare
  { name: 'Ambetter' }, //Modivcare
  { name: 'Blue Cross Blue Shield' }, //Modivcare
  { name: 'Humana' }, //Modivcare
  { name: 'Preferred' }, //Modivcare
  { name: 'Sunshine' }, //Modivcare
  { name: 'United Health Care' }, //Modivcare
].sort()

export default insurances
