import { toast } from 'react-toastify'
import { sendEmailAction } from '../actions/userActions'
import errTxt from './error-text'

const handleErr = ({ err, alert, customFunc, cb, dispatch, reduxType, payload }) => {
  const errString = JSON.stringify(err)
  const { errors } = err?.response?.data || { errors: '' }
  console.log('errors', errors)
  console.log(err)
  console.log(errTxt({ err: errors || err }))
  const errMsg = errTxt({ err: errors || err })
    ? errTxt({ err: errors || err })
    : err?.message
    ? err?.message
    : errors || ''
  console.log(errMsg)
  if (alert) {
    toast.dismiss()
    setTimeout(() => {
      toast.error(alert.msg || errMsg, {
        toastId: alert.id || 'error',
        // autoClose: alert.autoClose === false ? false : true,
      })
    }, 77)
  }
  customFunc && customFunc()
  cb && cb()
  if (dispatch) {
    dispatch(
      sendEmailAction({
        isError: true,
        to: `dev@xperdriver.com`,
        subject: `Front end Error ${errMsg}`,
        bodyContent: `${JSON.stringify(errors)} ${errString}`,
        // fileType: 'pdf',
        // fileBase64: `${fileBase64?.split('base64,')[1]}`,
        // isInvoice: true,
        // fileName: `${opt.filename}.pdf`,
        // cb: (data) => setNoPlaceholder(false),
      }),
    )
    if (reduxType && errMsg) {
      dispatch({
        type: reduxType,
        payload: payload || [errMsg],
      })
    }
  }
}

export default handleErr
