import { applyMiddleware, legacy_createStore as createStore, compose, combineReducers } from 'redux'
import thunk from 'redux-thunk'
// import { authReducer } from './reducers/authReducers'
import { companyAuthReducer, companyRegisterReducer } from './reducers/companyReducers'
import adminReducer from './reducers/adminReducer'
import userReducer from './reducers/userReducer'
import clockReducer from './reducers/clockReducer'
import absenseReducer from './reducers/absenseReducer'
import holidayReducer from './reducers/holidayReducer'
import insuranceReducer from './reducers/insuranceReducer'
import brokerReducer from './reducers/brokerReducer'
import brokerPricingReducer from './reducers/brokerPricingReducer'
import { sidebarReducer } from './reducers/sidebarReducers'
import handleIDB from './utils/handle-indexed-db'

const store = () =>
  new Promise(async (resolve, reject) => {
    try {
      const getCompanyAuth = async () => {
        try {
          const companyInfo = await handleIDB.getVal({ model: 'Auth', _id: 'companyData' })
          const userInfo = await handleIDB.getVal({ model: 'Auth', _id: 'userData' })
          const token = await handleIDB.getVal({ model: 'Auth', _id: 'XPERDRIVER_TOKEN' })
          const deviceDetails = userInfo?.email
            ? await handleIDB.getVal({ model: 'Device', _id: userInfo?.email })
            : ''
          return { companyInfo, userInfo, token, loading: false, deviceDetails }
          // companyAuth.companyInfo = companyInfo
          // companyAuth.userInfo = userInfo
        } catch (error) {
          console.log(error)
          return { companyInfo: '', userInfo: '', token: '', loading: false }
        }
      }
      // getCompanyAuth().then((companyAuth) => {
      const initialState = {
        companyAuth: await getCompanyAuth(),
        // sidebarShow: true,
      }

      const reducer = combineReducers({
        sidebar: sidebarReducer,
        companyRegister: companyRegisterReducer,
        companyAuth: companyAuthReducer,
        admin: adminReducer,
        user: userReducer,
        clock: clockReducer,
        absense: absenseReducer,
        holiday: holidayReducer,
        insurance: insuranceReducer,
        broker: brokerReducer,
        brokerPricing: brokerPricingReducer,
      })

      const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

      const store = createStore(reducer, initialState, composeEnhancer(applyMiddleware(thunk)))
      resolve(store)
      // })
    } catch (error) {
      console.log(error)
      reject('')
    }
  })

export default store
