//Array order is important!!!
const userLevels = [
  'Customer',
  // 'Service',
  'Driver',
  'Operator',
  'Dispatcher',
  'Supervisor',
  'Admin',
  'Superadmin',
]
export default userLevels
