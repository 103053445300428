import notify from './notify'

const geoLocation = () =>
  new Promise((resolve) => {
    const error = (err) => {
      console.error(`ERROR(${err?.code || ''}): ${err?.message || ''}`)
      resolve({ lng: 0, lat: 0 })
    }
    if (navigator && navigator.geolocation) {
      const success = (pos) => {
        const { longitude: lng = 0, latitude: lat = 0, heading, speed } = pos.coords
        resolve({ lng: Number(lng), lat: Number(lat), heading, speed })
      }
      const options = {
        enableHighAccuracy: true,
        timeout: 60000,
        maximumAge: 0,
      }
      navigator.permissions.query({ name: 'geolocation' }).then(async (result) => {
        const { state } = result
        if (state === 'granted' || state === 'prompt') {
          navigator.geolocation.getCurrentPosition(success, error, options)
        } else if (result.state === 'denied') {
          // Don't do anything if the permission was denied.

          notify({
            toastId: 'geolocation',
            type: 'warn',
            msg: 'Location Services is Blocked!!! Please Allow Location Services on your device Settings!!!',
            // confirm: () => {
            //   navigator.geolocation.getCurrentPosition(success, error, options)
            // },
          })
          // clearWatch(navId)
          // console.log('Location denied')
          resolve({ lng: 0, lat: 0 })
        }
        result.addEventListener('change', () => {
          if (state === 'granted') {
            navigator.geolocation.getCurrentPosition(success, error, options)
          }
          if (result.state === 'denied') {
            navigator.geolocation.getCurrentPosition(success, error, options)
          }
        })
      })
    } else {
      error()
    }
  })

export default geoLocation
